/* Container for the MentionsInput */
.mentions-input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.5;
  background: white;

  &.mentions-input--multiLine {
    min-height: 100px;
  }
}

/* Highlighter styles (background for mentions) */
.mentions-input__highlighter {
  color: transparent;
  white-space: pre-wrap;
  color: blue;
  word-wrap: break-word;
}

/* The input area of the MentionsInput */
.mentions-input__input {
  margin: 0;
  padding: 5px;
  border: none;
  outline: none;
  background: transparent;
  font-size: 14px;
  line-height: 1.5;
  width: 100%;
  height: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}

/* Mention suggestion list container */
.mentions-input__suggestions {
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 10;
  max-height: 200px;
  overflow-y: auto;
}

/* Each mention suggestion item */
.mentions-input__suggestions__item {
  padding: 5px 10px;
  cursor: pointer;
}

/* Highlight mention suggestion item when hovered */
.mentions-input__suggestions__item--focused {
  background-color: #ddd;
}

/* Style for individual mentions within the input */
.mentions-input__mention {
  background-color: #d1d1d1;
  padding: 2px 0;
  border-radius: 4px;
  position: relative;
  z-index: 1;
}
