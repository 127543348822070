.creatable-only.Select .Select-control .Select-arrow-zone {
  width: 10px;
  padding-right: 0;
  visibility: hidden;
}

.Select {
  width: 100%;
  height: 40px;
  border-radius: 4px;

  &:focus {
    outline: none;
  }

  &.is-disabled .Select-control .Select-input {
    height: 40px;
  }
}

.Select .Select-control {
  height: 40px;
}

.Select .Select-control .Select-value {
  display: inline-flex;
  width: auto;
  align-items: center;
  padding: 7px 15px;
}

.Select--multi.has-value .Select-control .Select-input {
  margin-left: 5px;
  padding: 0;
}

.Select .Select-control .Select-placeholder {
  display: block;
  width: 100%;
  align-items: center;
  padding: 5px 15px;
  height: 40px;
}

.Select .Select-control .Select-input {
  display: inline-flex !important; /* needs important because react-select adds styles inline */
  align-items: center;
  padding: 7px 15px;
}

.Select .Select-control .Select-arrow-zone {
  width: 25px;
  padding-right: 0px;
}

.Select .Select-control .Select-multi-value-wrapper {
  display: inline-flex;
  width: 100%;
  flex-wrap: wrap;
}

/*Single Select*/

.Select--single {
  .Select-control .Select-input {
    width: 100%;
  }
  .Select-menu-outer {
    .Select-option.is-focused {
      .Select-option.is-focused {
        background-color: #fce5ea;
      }
      .Select-option.is-selected {
        background-color: #ebecf0;
      }
    }
  }
}

/* Multi select */
.Select--multi {
  height: auto;
  min-height: 38px;

  .Select-menu-outer {
    .Select-option.is-focused {
      background-color: #fce5ea;
    }
    .Select-option.is-focused {
      background-color: #fce5ea;
    }
  }

  &.is-disabled > .Select-control {
    background: #e4e4e4;
  }
}
.Select--multi .Select-control .Select-input {
  margin-left: 0;
  margin-top: 0;
  height: 40px;
  input {
    height: 40px;
    padding: 0;
  }
}

.select-wrapper {
  .Select .Select-control .Select-multi-value-wrapper {
    display: block;
    height: 42px;
  }
  .Select--multi.has-value .Select-control .Select-multi-value-wrapper {
    margin-top: 30px;
    height: auto;
  }
  .Select .Select-control .Select-arrow-zone {
    position: absolute;
    right: 0;
    bottom: 15px;
    padding-right: 0px;
    width: 25px;
  }
  .Select-clear-zone {
    position: absolute;
    top: 0;
    left: 12px;
    span.Select-clear {
      font-size: 30px !important;
    }
    left: 12px;
    span.Select-clear {
      font-size: 30px !important;
    }
  }
}

$height-medium: 44px;
.archived-select.Select,
.md.Select {
  width: 200px;
  margin-right: 10px;
  height: $height-medium;
  .Select-value {
    height: $height-medium;
  }
  .Select-control {
    height: $height-medium;
  }

  .Select-control .Select-placeholder {
    height: $height-medium;
  }

  .Select-control .Select-input {
    height: $height-medium;
  }
}

// custom width styling
.Select.w-340 {
  min-width: 340px;
}

.Select-menu-outer {
  .Select-menu {
    .Select-option {
      &.is-focused {
        background-color: #fce5ea;
      }
    }
  }
}
